import React from "react";
import { FaClock, FaDollarSign, FaTruck } from "react-icons/fa";

const AboutUs = () => {
  return (
    <>
      <div className="w-full bg-[#f7f7f7] py-12 about-container">
        <div className="w-4/5 m-auto flex about-header">
          <div className="w-full m-auto">
            <h2 className="text-2xl font-bold text-[#232331] w-4/5">
              JSA is committed to making a positive impact on your business.
            </h2>
          </div>
          <div className="w-full ">
            <div className="grid grid-cols-3 justify-center gap-8 mt-8 about-header-services">
              <div className="aboutus-card bg-[#fff] p-4 rounded-lg flex flex-col items-center justify-center text-center w-4/5">
                <div className="aboutus-card-icon">
                  <FaDollarSign className="w-12 h-12 text-[#232331]" />
                </div>
                <h3 className="text-lg font-medium text-[#232331] mt-4">
                  Cost Optimization
                </h3>
              </div>
              <div className="aboutus-card bg-[#fff] p-4 rounded-lg flex flex-col items-center justify-center text-center w-4/5">
                <div className="aboutus-card-icon">
                  <FaClock className="w-12 h-12 text-[#232331]" />
                </div>
                <h3 className="text-lg font-medium text-[#232331] mt-4">
                  Reduced Transit Time
                </h3>
              </div>
              <div className="aboutus-card bg-[#fff] p-4 rounded-lg flex flex-col items-center justify-center text-center w-4/5">
                <div className="aboutus-card-icon">
                  <FaTruck className="w-12 h-12 text-[#232331]" />
                </div>
                <h3 className="text-lg font-medium text-[#232331] mt-4">
                  On-Time Delivery
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-4/5 m-auto px-4 py-12 flex gap-8 items-center about-body">
        <div className="aboutus-left-img-container">
          <img
            src="/241.jpg"
            alt=""
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="w-full flex flex-col gap-4">
          <h3 className="text-base font-medium tracking-widest text-[#ff1f1f]">
            About Company
          </h3>
          <h1 className="text-4xl font-bold text-[#232331]">
            Worldwide Logistics Solutions since 1996
          </h1>
          <p className="font-medium text-[#767676]">
            JSA Logistics is a full-service logistics company that provides
            innovative solutions for your complex logistics needs. Our
            experienced team of professionals will work with you to develop a
            customized logistics solution that meets your specific needs. We
            offer a wide range of services, including air freight, ocean
            freight, trucking, warehousing, and distribution. Whether you need
            to move a single shipment or manage a complex supply chain, JSA
            Logistics has the expertise and resources to get the job done right.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
