import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import Quote from "./Quote";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div className="header-container">
      <div className="flex justify-between items-center border-b border-[#5d5d85]">
        <div className="flex items-center gap-8">
          <div className="header-logo">
            <img src="/JSA Logo.jpeg" alt="" />
          </div>
          <div
            className={`flex gap-12 text-base font-medium text-[#fff] uppercase ${
              navOpen ? "nav-links active" : "nav-links"
            }`}
          >
            <HashLink to="/#home">Home</HashLink>
            <HashLink to="/#aboutus">About</HashLink>
            <HashLink to="/#services">Services</HashLink>
            <HashLink to="/#contact">Contact</HashLink>
          </div>
          <div className="hamburger-menu" onClick={() => setNavOpen(!navOpen)}>
            {/* Hamburger icon */}
            <span>☰</span>
          </div>
        </div>
        <div className="mr-8">
          <button
            className="get-quote-btn bg-[#ffca09] px-20 py-4 rounded-md w-fit font-bold text-[#ff1e1e]"
            onClick={() => setOpen(true)}
          >
            Get Quote
          </button>
        </div>
        <Quote open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

export default Header;
