import React from "react";
import {
  PiFacebookLogoFill,
  PiInstagramLogoFill,
  PiTwitterLogoFill,
} from "react-icons/pi";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white p-8">
      <div className="max-w-7xl flex gap-8 mx-auto footer-container">
        {/* About Section */}
        <div className=" mb-8 w-full">
          <div className="flex flex-col">
            <img src="/JSA Logo.jpeg" alt="Logo" className="mr-4" width={150} />
            <h3 className="text-lg font-bold">JSA BORNEO SDN BHD</h3>
          </div>
          <p className="mb-4 w-3/4">
            We work with a passion of taking challenges and creating new ones in
            advertising sector.
          </p>
          {/* <button className="bg-red-600 py-2 px-4 rounded">ABOUT</button> */}
        </div>

        {/* Services Section */}
        <div className="mb-8 w-full">
          <h3 className="text-lg font-bold mb-4 border-b-2 border-[#d70006] w-fit">
            Service
          </h3>
          <ul className="list-none font-semibold">
            <li className="mb-2">Logistics & Transport</li>
            <li className="mb-2">Door-To-Door Delivery</li>
            <li className="mb-2">Brunei Customs Declaration</li>
            <li className="mb-2">Logistics Distributions</li>
            <li className="mb-2">Land Trucking</li>
            <li className="mb-2">Warehousing</li>
          </ul>
        </div>
        <div className="mb-8 w-full">
          <div className="">
            <div className="flex items-center mb-4">
              <div className="ml-4 footer-address">
                <h3 className="text-lg font-bold border-b-2 border-[#d70006] w-fit">
                  Address
                </h3>
                <p className="">
                  INDUSTRIAL ESTATE, LOT 1242-43, JALAN DESA SENADIN 1 DESA
                  SENADIN, Jalan Lutong - Kuala Baram, ROAD, 98000 Miri,
                  Sarawak, Malaysia
                </p>
              </div>
            </div>
          </div>

          {/* Contact Section */}
          <div className="">
            <div className="flex items-center mb-4">
              <div className="ml-4 footer-contact">
                <h3 className="text-lg font-bold border-b-2 border-[#d70006] w-fit">
                  Contact
                </h3>
                <p>enquiry@jsaborneo.com</p>
                <p>+6011 5552 1166 (MALAYSIA)</p>
                <p>+6011 1919 1818(MALAYSIA)</p>
                <p>+673 862 1166 (BRUNEI)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-[#fff]">
        <p className="text-center">
          © 2024 JSA Borneo Sdn Bhd. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
