import React, { useState } from "react";
import Quote from "./Quote";

const Banner = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex w-4/5 m-auto my-8 items-center jsa-banner-container">
        <div className="jsa-banner-content-container">
          <h1 className="text-7xl font-bold text-[#fff] text-left ">
            Freight Management Services
          </h1>
          <button
            className="bg-[#ffca09] px-20 py-4 rounded-md w-fit font-bold text-[#ff1e1e] mt-8"
            onClick={() => setOpen(true)}
          >
            Get Quote
          </button>
        </div>
        <div className="w-full jsa-banner-image-container">
          <img
            src="/3d-delivery-truck-icon-isolated-background.png"
            alt="Delivery Truck Icon"
          />
        </div>
      </div>
      <Quote open={open} setOpen={setOpen} />
    </>
  );
};

export default Banner;
