import React from "react";
import Header from "./Header";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Contact from "./Contact";
import Footer from "./Footer";

const Main = () => {
  return (
    <div id="home">
      <div className="banner-header-wrapper bg-[#2c2c50db]">
        <img src="/hero-bg.jpg" alt="" className="banner-header-bg" />
        <div className="banner-header-wrapper-inner ">
          <Header />
          <Banner />
        </div>
      </div>
      <div id="aboutus"></div>
      <AboutUs />
      <div id="services"></div>
      <Services />
      <div id="contact"></div>
      <Contact />
      <Footer />
    </div>
  );
};

export default Main;
