import React, { useState } from "react";
import Quote from "./Quote";

const Contact = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="contact-section my-12">
        <div className="background-overlay bg-[#00000099] py-[3rem]">
          <div className="contact-content">
            <h2 className="text-[#fff]">GET IN TOUCH WITH US ANYTIME</h2>
            <h1 className="text-[#fff] contact-section-text">
              Looking for the best logistics transport service?
            </h1>
            <button
              className="quote-button bg-[#ffca09] px-20 py-4 rounded-md w-fit font-bold text-[#ff1e1e] mt-8"
              onClick={() => setOpen(true)}
            >
              Get a Quote
            </button>
          </div>
        </div>
      </div>
      <Quote open={open} setOpen={setOpen} />

    </>
  );
};

export default Contact;
