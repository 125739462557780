import React from "react";

const Services = () => {
  const services = [
    {
      id: 1,
      serviceCount: 1,
      title: "Logistics & Transport",
      image:
        "https://img.freepik.com/free-photo/forklift-boxes-assortment_23-2149853134.jpg?t=st=1717588312~exp=1717591912~hmac=162050407e90e9d1d0ea6c6c0b282933543ca819fca9e1d321378a69f412eaae&w=360",
    },
    {
      id: 2,
      serviceCount: 2,
      title: "Door-to-Door Delivery",
      image: "https://img.freepik.com/free-photo/man-pushing-packages-car_23-2148590683.jpg?t=st=1717591599~exp=1717595199~hmac=0aa6f05dc033bc9c2f4e11e2e6759a189b84090e5e6583cd9698f0a9db981514&w=360",
    },
    {
      id: 3,
      serviceCount: 3,
      title: "Brunei Customs Declaration",
      image: "https://img.freepik.com/free-photo/supply-chain-representation-still-life_23-2150172358.jpg?t=st=1717591707~exp=1717595307~hmac=52103ba71afed1a6d05971c545c1ef32590a761031f4c9cc152a26cdeb58cd83&w=360",
    },
    {
      id: 4,
      serviceCount: 4,
      title: "Logistics Distributions",
      image: "https://img.freepik.com/premium-photo/rear-view-man-working-construction-site_1048944-11799886.jpg?w=360",
    },
    {
      id: 5,
      serviceCount: 5,
      title: "Land Trucking",
      image: "https://img.freepik.com/free-photo/full-shot-woman-fixing-truck_23-2150263097.jpg?t=st=1717592041~exp=1717595641~hmac=2fc32ea534086d4cf65690a365c6cda91a312189ecc775871903bc163b8d74fd&w=360",
    },
    {
      id: 6,
      serviceCount: 6,
      title: "Warehousing",
      image: "https://img.freepik.com/free-photo/warehouse-asian-manager-distribution-operator-managing-stock-tracking-looking-cardboard-boxes-shelf-storehouse-employees-searching-goods-picking-customer-order_482257-71305.jpg?t=st=1717592116~exp=1717595716~hmac=d7d2efa987f15c808bfef35941a5151a22325dd4c87ed0024fb4de63f5e0e67d&w=360",
    },
  ];

  return (
    <div className="w-4/5 m-auto">
      <h1 className="text-3xl font-bold my-8">Our Services</h1>
      <div className="grid grid-cols-3 gap-8 services-card-container">
        {services.map((service) => (
          <div
            key={service.id}
            className="service-card border rounded-lg overflow-hidden shadow-lg relative w-[400px] h-[460px]"
            style={{
              background: "rgb(2,0,36)",
              background:
                "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.6811974789915967) 0%, rgba(40,214,249,0) 100%)",
            }}
          >
            <div className="service-image-wrapper">
              <img
                src={service.image}
                alt={service.title}
                className="service-image w-full h-full object-cover absolute"
                style={{ zIndex: -1 }}
              />
            </div>
            <div className="p-4 absolute bottom-0 text-[#fff]">
              <p className="font-semibold">Service #{service.serviceCount}</p>
              <h2 className="text-xl font-bold mb-2">{service.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
