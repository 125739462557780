import logo from "./logo.svg";
import "./App.css";

import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/Main";

function App() {
  return (
    <div className="overflow-hidden">
      <BrowserRouter>
        <Suspense
          fallback={
            <div>
              <img src="/images/loading.svg" alt="Loading" srcSet="" />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Main />} exact={true} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
